<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card flat max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent mb-3">
          <h1 class="headline black--text">{{ $t('talonario.conf_firma') }}</h1>
          <v-spacer />
        </v-toolbar>
        <div id="firma" class="firma">
          <v-card class="mb-4">
            <v-card-text>
              <v-alert
                v-if="!loading && firma === ''"
                color="red lighten-4"
                class="brown--text mb-3"
                icon="mdi-gesture"
                :value="true"
                >{{ $t('talonario.sin_firma') }}</v-alert
              >
              <div>{{ $t('talonario.explicacion_firma') }}</div>
              <center>
                <div id="firma_Container" style="height: 180px; cursor: default;">
                  <div v-if="!modificar_signature">
                    <img v-if="firma !== ''" :src="firma" alt="signature" class="firma_creada" />
                  </div>
                  <v-signature
                    v-if="modificar_signature"
                    ref="signature"
                    data-v-step="0"
                    :sig-option="option"
                    :w="'300px'"
                    :h="'180px'"
                    class="firma_nueva mb-3"
                  />
                  <v-btn data-test="settings-button-redo" data-v-step="1" :disabled="disabledButton" text small color="primary" @click="clear">{{
                    $t('talonario.rehacer_firma')
                  }}</v-btn>
                </div>
                <br clear="all" />
                <br clear="all" />
              </center>
              <div class="botonera">
                <v-btn
                  data-test="settings-button-send-link"
                  data-v-step="2"
                  :disabled="disabledButton"
                  color="primary"
                  text
                  depressed
                  dark
                  class="mr-2"
                  @click.stop="mandarLink"
                  >{{ $t('talonario.mandame_link', { nombre: $t('common.movil') }) }}</v-btn
                >
                <v-btn data-test="settings-button-save" data-v-step="3" :disabled="disabledButton" color="primary" depressed dark @click="saveFirma">{{
                  $t('common.save')
                }}</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon>
      &nbsp;{{ alerta.texto }}
    </v-snackbar>
    <v-dialog v-model="dialogSalirSinGuardar" width="500">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('common.titulo_salir_sin_guardar') }}</v-toolbar-title>
          <v-spacer />
          <v-btn v-tooltip="$t('common.close')" icon @click="dialogSalirSinGuardar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
          <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.titulo_salir_sin_guardar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tour name="myTour" :steps="steps" :options="myOptions" />
  </v-container>
</template>

<script>
import vueSignature from '@/components/vueSignature.vue';
import { talonarioUrl, firmaUrl, getHeader, getHeaderMultimedia } from '@/config/config';

export default {
  components: {
    'v-signature': vueSignature,
  },
  data: () => ({
    dialogSalirSinGuardar: false,
    pageNext: {},
    esta_modificando: false,
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    firma: '',
    loading: true,
    modificar_signature: false,
    disabledButton: false,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
    },
    option: {
      penColor: 'rgb(46, 59, 165)',
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
    confirmar: {
      dialog: false,
      titulo: '',
      texto: '',
      icono: '',
      color: '',
      id: 0,
      accion: '',
      indice: 0,
    },
    steps: [
      { target: '[data-v-step="0"]', content: '' },
      { target: '[data-v-step="1"]', content: '' },
      { target: '[data-v-step="2"]', content: '' },
      { target: '[data-v-step="3"]', content: '', params: { placement: 'top' } },
    ],
    myOptions: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: 'Skip tour',
        buttonPrevious: 'Previous',
        buttonNext: 'Next',
        buttonStop: 'Finish',
      },
    },
    loading_upload: false,
    custom_template:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== '',
  }),

  computed: {
    shouldShowTour() {
      return false;
    },
  },

  created() {
    const formData = new FormData();
    formData.append('user_id', this.authUser.id);
    formData.append('accion', 'indexFirma');
    this.$http
      .post(talonarioUrl, formData, { headers: getHeader() })
      .then(response => {
        if (response.status === 200 && response.data) {
          this.loading = false;
          if (response.data.firma !== '') {
            this.firma = response.data.firma;
          } else if (this.shouldShowTour) {
            this.clear();
            this.modificar_signature = true;
            this.myOptions.labels.buttonSkip = this.$t('tour.skip');
            this.myOptions.labels.buttonPrevious = this.$t('tour.prev');
            this.myOptions.labels.buttonNext = this.$t('tour.next');
            this.myOptions.labels.buttonStop = this.$t('tour.finish');
            this.steps[0].content = this.$t('tour.dibuja_firma');
            this.steps[1].content = this.$t('tour.no_te_gusta') + '<br>' + this.$t('tour.rehacer_firma');
            this.steps[2].content = this.$t('tour.enviar_firma_mobile', {
              nombre: this.$t('common.movil'),
            });
            this.steps[3].content = this.$t('tour.guardar_cambios');
            this.$tours['myTour'].start();
          } else {
            this.clear();
          }
        }
      })
      .catch(err => {
        this.$log.error(err);
      });
  },

  methods: {
    saveFirma() {
      if (typeof this.$refs.signature !== 'undefined') {
        const png = this.$refs.signature.save();
        if (!this.disabledButton) {
          this.disabledButton = true;
          const formData = new FormData();
          formData.append('firma', png);
          formData.append('user_id', this.authUser.id);
          formData.append('accion', 'guardar');
          this.$http
            .post(firmaUrl, formData, { headers: getHeaderMultimedia() })
            .then(response => {
              this.$tours['myTour'].stop();
              if (response.status === 200 && response.data.estado === '1') {
                this.alerta.texto = this.$t('success.save_firma');
                this.alerta.color = 'success';
                this.alerta.icono = 'mdi-gesture';
                this.firma = response.data.firma;
              } else {
                this.alerta.texto = this.$t('errors.try_again');
                this.alerta.color = 'error';
                this.alerta.icono = 'mdi-alert-circle';
              }
              this.snackbar = true;
              this.disabledButton = false;
              this.esta_modificando = false;
            })
            .catch(err => {
              this.$log.error(err);
            });
        }
      }
    },

    clear() {
      this.modificar_signature = true;
      this.esta_modificando = true;
      setTimeout(() => {
        this.$refs.signature.clear();
      }, 500);
    },
    mandarLink() {
      const formData = new FormData();
      formData.append('user_id', this.authUser.id);
      formData.append('accion', 'mandarLink');
      this.$http
        .post(firmaUrl, formData, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.alerta.texto = this.$t('talonario.send_signature');
            this.alerta.color = 'info';
            this.alerta.icono = 'mdi-gesture';
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.snackbar = true;
        })
        .catch(err => {
          this.$log.error(err);
        });
    },
    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.esta_modificando && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
<style scoped lang="css">
.instrucciones {
  margin-left: 20px;
}
.firma_nueva {
  border: 2px dashed rgb(221, 221, 221);
  z-index: 99;
  cursor: url(/static/pen.cur), pointer;
  height: 180px;
  background-color: rgb(255, 255, 255);
}
.firma_creada {
  border: 2px dashed rgb(221, 221, 221);
  z-index: 99;
  height: 180px;
  margin-bottom: -5px;
  background-color: rgb(255, 255, 255);
}
.botonera {
  text-align: right;
}
.loading_firma {
  margin-top: 10px;
  text-align: center;
}
</style>
