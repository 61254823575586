<template>
  <div :style="{ width: w, height: h }" @touchmove.prevent>
    <canvas :id="uid" class="canvas" :data-uid="uid" :disabled="disabled" />
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';

export default {
  name: 'VueSignature',
  props: {
    sigOption: {
      type: Object,
      default: () => {
        return {
          backgroundColor: 'rgb(255,255,255)',
          penColor: 'rgb(0, 0, 0)',
        };
      },
    },
    w: {
      type: String,
      default: '100%',
    },
    h: {
      type: String,
      default: '100%',
    },
    clearOnResize: {
      type: Boolean,
      default: false,
    },
    waterMark: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sig: () => {
        /** */
      },
      option: {
        backgroundColor: 'rgb(255,255,255)',
        penColor: 'rgb(0, 0, 0)',
      },
      uid: '',
    };
  },
  watch: {
    disabled(val) {
      if (val) {
        this.sig.off();
      } else {
        this.sig.on();
      }
    },
  },
  created() {
    this.uid = 'canvas' + this._uid;
    const sigOptions = Object.keys(this.sigOption);
    for (const item of sigOptions) {
      this.option[item] = this.sigOption[item];
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.draw();
    });
  },
  methods: {
    draw() {
      const canvas = document.getElementById(this.uid);
      this.sig = new SignaturePad(canvas, this.option);
      const resizeCanvas = () => {
        let url;
        if (!this.isEmpty()) {
          url = this.save();
        }
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);
        this.clear();
        !this.clearOnResize && url !== undefined && this.fromDataURL(url);
        Object.keys(this.waterMark).length && this.addWaterMark(this.waterMark);
      };

      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();

      if (this.defaultUrl !== '') {
        this.fromDataURL(this.defaultUrl);
      }

      if (this.disabled) {
        this.sig.off();
      } else {
        this.sig.on();
      }
    },
    clear() {
      this.sig.clear();
    },
    save(format) {
      return format ? this.sig.toDataURL(format) : this.sig.toDataURL();
      // signaturePad.toDataURL(); // save image as PNG
      // signaturePad.toDataURL("image/jpeg"); // save image as JPEG
      // signaturePad.toDataURL("image/svg+xml"); // save image as SVG
    },
    fromDataURL(url) {
      this.sig.fromDataURL(url);
    },
    isEmpty() {
      return this.sig.isEmpty();
    },
    undo() {
      const data = this.sig.toData();
      if (data) {
        data.pop();
        this.sig.fromData(data);
      }
    },
    addWaterMark(data) {
      if (!(Object.prototype.toString.call(data) === '[object Object]')) {
        throw new Error('Expected Object, got ' + typeof data + '.');
      } else {
        const vCanvas = document.getElementById(this.uid);

        const textData = {
          text: data.text || '',
          x: data.x || 20,
          y: data.y || 20,
          sx: data.sx || 40,
          sy: data.sy || 40,
        };

        const ctx = vCanvas.getContext('2d');
        ctx.font = data.font || '20px sans-serif';
        ctx.fillStyle = data.fillStyle || '#333';
        ctx.strokeStyle = data.strokeStyle || '#333';
        if (data.style === 'all') {
          ctx.fillText(textData.text, textData.x, textData.y);
          ctx.strokeText(textData.text, textData.sx, textData.sx);
        } else if (data.style === 'stroke') {
          ctx.strokeText(textData.text, textData.sx, textData.sx);
        } else {
          ctx.fillText(textData.text, textData.x, textData.y);
        }

        this.sig._isEmpty = false;
      }
    },
  },
};
</script>

<style>
canvas {
  width: 100%;
  height: 100%;
}
</style>
